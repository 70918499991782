.puzzle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 91vh;
  padding: 20px;
  background-color: #ffffff;
  color: #000;
  position: relative;
}

.puzzle-container h1 {
  color: #4d7efe;
  font-size: 24px;
  margin-bottom: 20px;
}
.puzzle-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  position: relative;
}
.puzzle-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.puzzle-container input {
  padding: 10px;
  margin-top: 30px;
  border: 1px solid #4d7efe;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
  box-shadow: none;
}

.puzzle-container input:focus {
  border-color: #4d7efe;
  box-shadow: none;
}

.puzzle-container button {
  background-color: #4d7efe;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.puzzle-container button:hover {
  background-color: #6b94ff;
}

.puzzle-message {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: #4d7efe;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.hint-container {
  margin-top: 30px;
  text-align: center;
}

.hint-title {
  font-size: 20px;
  margin-top: 30px;
  color: #4d7efe;
  margin-bottom: 10px;
}

.puzzle-hint {
  color: #4d7efe;
  margin: 10px;
}

.puzzle-audio {
  max-width: 100%;
  margin-bottom: 20px;
}
.completion-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #ffffff;
  color: #000;
}

.completion-message h1 {
  color: #4d7efe;
  font-size: 24px;
  margin-bottom: 20px;
}

.back-link {
  display: inline-block;
  margin-top: 20px;
  font-size: 16px;
  color: #4d7efe;
  text-decoration: none;
}

.back-link:hover {
  text-decoration: underline;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  background-color: #4d7efe;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.modal-content button:hover {
  background-color: #6b94ff;
}
