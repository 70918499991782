body {
  overflow-x: hidden;
}

footer {
  width: 100%;
  padding: 30px;
  background-color: #0b162d;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-header {
  color: #4d7efe;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-header-left {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.footer-header-left h1 {
  color: #4d7efe;
  font-size: 17px;
  margin: 0;
}

.footer-header-right {
  display: flex;
  align-items: center;
}

.login-button {
  background: none;
  color: #fff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.login-icon {
  font-weight: 700;
  width: 16px;
  height: 16px;
  margin-right: 15px;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-column {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.footer-column:last-child {
  margin-right: 0;
}

.footer-column:last-child {
  margin-left: auto;
}

.footer-column h2 {
  color: #4d7efe;
  font-size: 12px;
  margin-bottom: 0;
}

.footer-column p {
  font-size: 12px;
}

.footer-column .vk-container {
  display: flex;
  align-items: center;
}

.footer-column .vk {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  width: 100%;
}

@media (min-width: 768px) {
  footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
  }

  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-column {
    width: 30%;
    margin-bottom: 0;
  }

  .login-button {
    font-size: 16px;
  }

  .modal-content {
    padding: 40px;
    width: 400px;
  }
}
