.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

.closeButton {
  position: absolute;
  color: #4d7efe;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.modalContent h2 {
  color: #000000;
  font-size: 15px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

label {
  color: #000000;
  font-size: 12px;
  font-weight: bolder;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}

input {
  width: 100%;
  padding: 10px;
  color: rgb(0, 0, 0);
  margin-top: 5px;
  border: 1px solid #4d7efe;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #ffffff;
}

input:focus {
  outline: none; /* Убирает стандартную подсветку */
  border: 1px solid #4d7efe; /* Оставляет ваш собственный стиль рамки */
  box-shadow: none; /* Убирает любые дополнительные тени, если есть */
}

.loginButton {
  background-color: #4d7efe;
  border: 1px solid #4d7efe;
  color: #ffffff;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bolder;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.loginButton:hover {
  transform: scale(1.05);
}

.resultContent {
  background-color: #ffffff;
  padding: 20px;
  max-width: 400px;
  text-align: center;
}

.resultContent h2 {
  color: #000000;
  font-size: 15px;
  margin-bottom: 20px;
}

.resultContent button {
  background-color: #4d7efe;
  border: 1px solid #4d7efe;
  color: #ffffff;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bolder;
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease;
}

.resultContent button:hover {
  transform: scale(1.05);
}

.loginError {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.passwordField {
  font-family: "Courier New", Courier, monospace;
  letter-spacing: 2px;
}
